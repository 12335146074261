import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MnbQuickTimeFilterValue, MnbQuickTimeFilterComponent } from './mnb-quick-time-filter.component';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import deepEqual from 'deep-equal';

@Component({
    selector: 'mnb-reactive-quick-time-filter',
    templateUrl: './mnb-reactive-quick-time-filter.component.html'
})
export class MnbReactiveQuickTimeFilterComponent {
    @Input()
    public set value(value: MnbQuickTimeFilterValue) {
        this.valueSub$.next(value);
    }
    @Input() originalValue: MnbQuickTimeFilterValue;
    @Input() withComparisonPeriodToggle: boolean;
    @Input() disableComparisonOption = false;
    @Output() valueChange = new EventEmitter<MnbQuickTimeFilterValue>();

    public valueSub$ = new BehaviorSubject<MnbQuickTimeFilterValue>(null);
    public value$ = this.valueSub$.asObservable().pipe(
        distinctUntilChanged(deepEqual),
        tap(() => {
            if (this.childComponent && this.childComponent.model) {
                this.isOpened = this.childComponent.model.isOpened;
            }
            this.active = false;
            setTimeout(() => this.active = true);
        })
    );

    @ViewChild(MnbQuickTimeFilterComponent, { static: false }) childComponent: MnbQuickTimeFilterComponent;

    public isOpened = false;
    public active = true;

    public onValueChange(value: MnbQuickTimeFilterValue) {
        this.valueChange.emit(value);
    }

    constructor() {}

}
