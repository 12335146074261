import { Component } from '@angular/core';
import { ReportsBrandShareService } from '../service/reports-brand-share.service';
import { ReportsBrandShareChartService } from '../service/reports-brand-share-chart.service';


@Component({
    selector: 'reports-brand-share',
    templateUrl: './reports-brand-share.component.html',
    styleUrls: ['./reports-brand-share.component.less'],
    providers: [ReportsBrandShareService, ReportsBrandShareChartService],
})
export class ReportsBrandShareComponent {

    constructor(
        private brandShareService: ReportsBrandShareService,
        private brandShareChartService: ReportsBrandShareChartService
    ) { }

}
