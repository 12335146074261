import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MnbForm } from '@minubo-suite/shared/modules/form';
import { MnbDropdownInputGroup, MnbDropdownInputGroupConfig } from '@shared-lib/modules/core/components/dropdown-input/mnb-dropdown-input.component';
import { DateSpan } from '@shared-lib/modules/core/model/mnb-time.model';
import { MnbDateSpanPipe } from '@shared-lib/modules/core/pipes/date-span.pipe';
import { TimeFilterService } from '@shared-lib/modules/core/services/time/time-filter.service';
import { ExtendedComparisonCode, TimeComparisonFilter, TimeComparisonFilterOptions, TimeComparisonOption, TimeComparisonOptionCode, TimeFilterOption, TimeFilterOptions } from '@shared-lib/modules/core/services/time/time.model';
import { deepCopy } from '@shared-lib/modules/core/utils/deep-copy.util';
import { QuerySettingsComparisonFilter, QuerySettingsTimeFilter, QuerySettingsTimeFilterOptionCode, TimeTypeCode } from '@shared-lib/modules/data/model/mnb-data-query.model';
import deepEqual from 'deep-equal';
import { isNullOrUndefined } from 'util';
import { MnbDateSpanPickerComponent } from '@shared-lib/modules/core/components/date-span-picker/mnb-date-span-picker.component';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'mnb-quick-time-filter',
    templateUrl: './mnb-quick-time-filter.component.html'
})
export class MnbQuickTimeFilterComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();
    public model: QuickDatePickerModel;
    public showResetButton: boolean;

    public groupConfig = new MnbDropdownInputGroupConfig([
        new MnbDropdownInputGroup('GENERAL.TIME.LABEL.DAYS', TimeTypeCode.Days),
        new MnbDropdownInputGroup('GENERAL.TIME.LABEL.WEEKS', TimeTypeCode.Weeks),
        new MnbDropdownInputGroup('GENERAL.TIME.LABEL.MONTHS', TimeTypeCode.Months),
        new MnbDropdownInputGroup('GENERAL.TIME.LABEL.YEARS', TimeTypeCode.Years),
        new MnbDropdownInputGroup('GENERAL.TIME.LABEL.FISCAL_YEARS', TimeTypeCode.FiscalYears),
        new MnbDropdownInputGroup('GENERAL.TIME.TIME_OPTIONS.CUSTOM', null)
    ], 'typeCode');

    @Input() value: MnbQuickTimeFilterValue;
    @Input() originalValue: MnbQuickTimeFilterValue;

    /**
     * Dont use these inputs any more! The value input is much cleaner
     */
    @Input() timeFilterOption: TimeFilterOption;
    @Input() comparisonOption: TimeComparisonOption;
    @Input() originalTimeFilterOption: TimeFilterOption;
    @Input() originalComparisonOption: TimeComparisonOption;
    @Input() withResetButton: boolean;
    @Input() withComparisonPeriodToggle: boolean;

    @Input() allowToday: boolean;
    @Input() isOpened = false;
    @Input() disableComparisonOption = false;

    @Output() timeChange = new EventEmitter<AlyQuickDatePickerTimeChange>();
    @Output() valueChange = new EventEmitter<MnbQuickTimeFilterValue>();

    @ViewChildren(MnbDateSpanPickerComponent) dateSpanPicker: QueryList<MnbDateSpanPickerComponent>;

    public onOutsideClick() {
        if (!this.model) {
            return;
        }
        this.model.isOpened = false;
    }

    public onClick() {
        if (!this.model) {
            return;
        }
        this.model.isOpened = !this.model.isOpened;
    }

    onResetClick(e: Event): void {
        e.stopPropagation();
        this.onReset();
    }

    constructor(
        private timeFilterService: TimeFilterService,
        private datePipe: MnbDateSpanPipe,
    ) { }

    ngOnInit(): void {
        if (this.value) {
            this.timeFilterOption = deepCopy(TimeFilterOptions.getByCode(this.value.timeFilter.optionCode));

            if (this.timeFilterOption.timeFilter.optionCode === 'custom' && !!this.value.timeFilter.from.date) {
                this.timeFilterOption.timeFilter.from.date = new Date(this.value.timeFilter.from.date);
                this.timeFilterOption.timeFilter.to.date = new Date(this.value.timeFilter.to.date);
            }

            this.comparisonOption = deepCopy(this.timeFilterService.getComparisonOption(<TimeComparisonFilter> this.value.comparisonFilter));

            if (this.comparisonOption && this.comparisonOption.filter.optionCode === 'dateRange' && !!this.value.comparisonFilter.fromDate) {
                this.comparisonOption.filter.fromDate = new Date(this.value.comparisonFilter.fromDate);
                this.comparisonOption.filter.toDate = new Date(this.value.comparisonFilter.toDate);
            }
        }
        if (this.originalValue) {
            this.originalTimeFilterOption = deepCopy(TimeFilterOptions.getByCode(this.originalValue.timeFilter.optionCode));
            this.originalComparisonOption = deepCopy(this.timeFilterService.getComparisonOption(<TimeComparisonFilter> this.originalValue.comparisonFilter));
        }
        this.model = new QuickDatePickerModel(
            this.timeFilterOption,
            this.comparisonOption,
            this.timeFilterService,
            this.withComparisonPeriodToggle,
            this.datePipe,
            this.allowToday,
            this.isOpened,
            this.disableComparisonOption,
        );

        this.groupConfig = createGroupConfig(this.timeFilterService.hasFiscalYearConfigured());

        this.showResetButton = this.withResetButton && (this.model.comparisonOption && !deepEqual(this.originalComparisonOption, this.model.comparisonOption) || !deepEqual(this.originalTimeFilterOption, this.model.timeFilterOption));

        this.model.changes.subscribe(() => {
            this.showResetButton = this.withResetButton && (this.model.comparisonOption && !deepEqual(this.originalComparisonOption, this.model.comparisonOption) || !deepEqual(this.originalTimeFilterOption, this.model.timeFilterOption));
            const value = this.model.editForm.value;
            this.timeChange.emit(value);

            const timeFilterOption = TimeFilterOptions.getByCode(value.optionCode);

            if (value.optionCode === QuerySettingsTimeFilterOptionCode.CUSTOM) {
                timeFilterOption.timeFilter.from = {
                    date: value.fromDate
                };
                timeFilterOption.timeFilter.to = {
                    date: value.toDate
                };
            }

            const comparisonFilterOption = value.comparisonOptionCode ? TimeComparisonFilterOptions.getByCode(value.comparisonOptionCode) : null;

            if (value.comparisonOptionCode === TimeComparisonOptionCode.DateRange) {
                comparisonFilterOption.filter.fromDate = value.comparisonFromDate;
                comparisonFilterOption.filter.toDate = value.comparisonToDate;
            }

            this.valueChange.emit({
                timeFilter: timeFilterOption.timeFilter,
                comparisonFilter: comparisonFilterOption ? comparisonFilterOption.filter : null
            });

        });

        this.model.isCustomSelected$
            .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
            .subscribe((isCustomSelected) => {
                if (!this.dateSpanPicker) {
                    return;
                }
                if (isCustomSelected) {
                    this.dateSpanPicker.first.openCalendar();
                } else {
                    this.dateSpanPicker.first.closeCalendar();
                }
            });

        this.model.isComparisonCustomSelected$
            .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
            .subscribe((isCustomSelected) => {
                if (!this.dateSpanPicker) {
                    return;
                }
                if (isCustomSelected) {
                    this.dateSpanPicker.last.openCalendar();
                } else {
                    this.dateSpanPicker.last.closeCalendar();
                }
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onCustomDateChange(dateSpan: DateSpan) {
        const customOption = TimeFilterOptions.CUSTOM_OPTION_YEAR;
        customOption.timeFilter.from.date = dateSpan.from;
        customOption.timeFilter.to.date = dateSpan.to;
        this.model.update(customOption);
    }

    onComparisonCustomDateChange(dateSpan: DateSpan) {
        const comparisonOption = TimeComparisonFilterOptions.DATE_RANGE;
        comparisonOption.filter.toDate = dateSpan.to;
        comparisonOption.filter.fromDate = dateSpan.from;
        this.model.update(null, comparisonOption);
    }

    onReset(): void {
        if (this.withComparisonPeriodToggle) {
            this.model.editForm.controls.comparisonPeriodEnabled.setValue(!isNullOrUndefined(this.originalComparisonOption), { emitEvent: false });
        }

        this.model.update(this.originalTimeFilterOption, this.originalComparisonOption);
    }

}

class QuickDatePickerModel {

    public editForm: MnbForm;
    public title: string;
    public description: string;
    public dateSpan: DateSpan;
    public comparisonDateSpan: DateSpan;
    public timeFilterOptions: TimeFilterOption[];
    public comparisonOptions: TimeComparisonOption[];
    public changes = new EventEmitter();
    public isCustomSelected$: Observable<boolean>;
    public isComparisonCustomSelected$: Observable<boolean>;

    private isUpdating: boolean;
    private hasComparisonPeroid: boolean;

    constructor(
        public timeFilterOption: TimeFilterOption,
        public comparisonOption: TimeComparisonOption,
        private timeFilterService: TimeFilterService,
        private withComparisonPeriodToggle: boolean,
        private datePipe: MnbDateSpanPipe,
        public allowToday: boolean,
        public isOpened: boolean,
        private disableComparisonOption: boolean,
    ) {

        this.dateSpan = this.timeFilterService.getTimePeriod(timeFilterOption.timeFilter);
        if (comparisonOption) {
            this.comparisonDateSpan = this.timeFilterService.getComparisonPeriod(timeFilterOption.timeFilter, comparisonOption.filter);
        } else {
            this.comparisonDateSpan = new DateSpan(this.dateSpan.from, this.dateSpan.to);
        }
        this.editForm = new MnbForm({
            'fromDate': new FormControl(this.dateSpan.from),
            'toDate': new FormControl(this.dateSpan.to),
            'comparisonFromDate': new FormControl(this.comparisonDateSpan.from),
            'comparisonToDate': new FormControl(this.comparisonDateSpan.to),
            'optionCode': this.createControl(timeFilterOption.code),
            'comparisonOptionCode': this.createControl(comparisonOption ? comparisonOption.code : null)
        });

        if (this.withComparisonPeriodToggle) {
            this.editForm.addControl('comparisonPeriodEnabled', new FormControl(!isNullOrUndefined(comparisonOption)));

            this.editForm.controls.comparisonPeriodEnabled.valueChanges.subscribe((isEnabled) => {
                if (isEnabled) {
                    const comparisonFilterOption = TimeComparisonFilterOptions.getByCode(TimeComparisonOptionCode.AdjoiningPeriod);
                    comparisonFilterOption.filter.index = this.timeFilterService.calculateAdjoiningPeriodDayCount(this.timeFilterOption.timeFilter);
                    setTimeout(() => this.update(this.timeFilterOption, comparisonFilterOption));
                } else {
                    setTimeout(() => this.update(this.timeFilterOption, null));
                }
            });
        }

        this.update(this.timeFilterOption, this.comparisonOption, true);

        this.isCustomSelected$ = this.editForm.get('optionCode').valueChanges.pipe(
            startWith(timeFilterOption.code),
            map(value => value === QuerySettingsTimeFilterOptionCode.CUSTOM),
        );

        this.isComparisonCustomSelected$ = this.editForm.get('comparisonOptionCode').valueChanges.pipe(
            startWith(this.editForm.get('comparisonOptionCode').value),
            map(value => value === TimeComparisonOptionCode.DateRange),
        );

    }

    private createControl(value: any): FormControl {
        const control = new FormControl(value);
        control.valueChanges.subscribe(() => setTimeout(() => this.update()));
        return control;
    }

    private calcAvailableOptions(timeFilterOption: TimeFilterOption) {
        const TimePeriod = TimeTypeCode;
        const ComparisonCode = TimeComparisonOptionCode;
        const TimeCode = QuerySettingsTimeFilterOptionCode;

        const isCustomSelected = timeFilterOption.code === TimeCode.CUSTOM;
        const isToday = timeFilterOption.code === TimeCode.TODAY;
        const isYesterday = timeFilterOption.code === TimeCode.YESTERDAY;

        let avaliableComparisonOptions: (TimeComparisonOptionCode | ExtendedComparisonCode)[] = [
            ComparisonCode.DateRange,
            ComparisonCode.AdjoiningPeriod
        ];

        if (isYesterday || isToday) {
            avaliableComparisonOptions.push(ComparisonCode.YearOverYearByDate);
        }

        if (isCustomSelected) {
            avaliableComparisonOptions.push(ComparisonCode.YearOverYearByDate);
            avaliableComparisonOptions.push(ComparisonCode.YearOverYearByWeekNumber);
        }

        if (!isCustomSelected && !isYesterday && !isToday) {
            avaliableComparisonOptions = [ComparisonCode.DateRange];

            const comparisonMaps = [
                new ComparisonPeriodsMap([
                    TimeCode.LAST_7_DAYS,
                    TimeCode.LAST_30_DAYS,
                    TimeCode.LAST_365_DAYS,
                    TimeCode.LAST_QUARTER,
                    TimeCode.LAST_3_QUARTERS,
                    TimeCode.LAST_WEEK,
                    TimeCode.LAST_52_WEEKS,
                    TimeCode.LAST_MONTH,
                    TimeCode.LAST_12_MONTHS,
                    TimeCode.LAST_YEAR,
                    TimeCode.LAST_FISCAL_YEAR,
                ], [
                    [ ComparisonCode.YearOverYearByDate, [ TimePeriod.Days, TimePeriod.Months, TimePeriod.Years, TimePeriod.FiscalYears ] ],
                    [ ComparisonCode.YearOverYearByWeekNumber, [ TimePeriod.Days, TimePeriod.Weeks, TimePeriod.Quarters, TimePeriod.Months ] ]
                ], [ComparisonCode.AdjoiningPeriod]),
                new ComparisonPeriodsMap([
                    TimeCode.THIS_WEEK_TO_DATE,
                    TimeCode.THIS_MONTH_TO_DATE,
                    TimeCode.THIS_QUARTER_TO_DATE,
                    TimeCode.THIS_YEAR_TO_DATE,
                    TimeCode.THIS_FISCAL_YEAR_TO_DATE
                ], [
                    [ ExtendedComparisonCode.WEEK_OVER_WEEK, [ TimePeriod.Weeks ] ],
                    [ ExtendedComparisonCode.MONTH_OVER_MONTH, [ TimePeriod.Months, TimePeriod.Quarters ] ],
                    [ ComparisonCode.YearOverYearByDate, [ TimePeriod.Days, TimePeriod.Months, TimePeriod.Years, TimePeriod.FiscalYears ] ],
                    [ ComparisonCode.YearOverYearByWeekNumber, [ TimePeriod.Weeks, TimePeriod.Months ] ]
                ])
            ];

            comparisonMaps.forEach(comparisonMap => {
                if (comparisonMap.matchedOptions.includes(timeFilterOption.code)) {
                    if (comparisonMap.allPeriods) {
                        comparisonMap.allPeriods.forEach(comparisonPeriod => avaliableComparisonOptions.push(comparisonPeriod));
                    }
                    Array.from(comparisonMap.periods.keys()).forEach(comparisonPeriod => {
                        if (comparisonMap.periods.get(comparisonPeriod).includes(timeFilterOption.typeCode)) {
                            avaliableComparisonOptions.push(comparisonPeriod);
                        }
                    });
                }
            });
        }

        const customOption = TimeFilterOptions.CUSTOM_OPTION_YEAR;

        const fiscalConfigured = this.timeFilterService.hasFiscalYearConfigured();
        const filterFiscalOptionsIfFiscalYearNotConfigured = (value: TimeFilterOption) => {
            if (value.typeCode !== TimeTypeCode.FiscalYears) {
                return true;
            }
            return fiscalConfigured;
        };

        const timeFilterOptions = [...TimeFilterOptions.getAll(this.allowToday)]
            .filter(value => value.code !== TimeCode.CUSTOM)
            .filter(filterFiscalOptionsIfFiscalYearNotConfigured)
            .concat(customOption)
            .map(value => {
                const isCustomOption = value.code === QuerySettingsTimeFilterOptionCode.CUSTOM;
                const option = {
                    ...value,
                    info: isCustomOption ? '' : '(' + this.timeFilterService.getTimeFilterDateSpanText(value.timeFilter, { shorten: true }) + ')'
                };
                this.timeFilterService.getTimeLabel(value.timeFilter, null).then(label => option.label = label);
                return option;
            });

        const getComparisonCode = (value: TimeComparisonOption) => value.extendedCode ? value.extendedCode : value.code;

        const comparisonOptions: TimeComparisonOption[] = [
            ...TimeComparisonFilterOptions.VALUES,
            ...TimeComparisonFilterOptions.EXTENDED_VALUES
        ]
            .filter(value => avaliableComparisonOptions.includes(getComparisonCode(value)))
            .map(v => {
                if (TimeComparisonOptionCode.AdjoiningPeriod === v.code) {
                    v.filter.index = this.timeFilterService.calculateAdjoiningPeriodDayCount(timeFilterOption.timeFilter);
                }

                let info = '(' + this.datePipe.transform(this.timeFilterService.getComparisonPeriod(timeFilterOption.timeFilter, v.filter), { shorten: true }) + ')';
                if (v.code === TimeComparisonOptionCode.DateRange) {
                    info = '';
                }

                const option: any = {
                    ...v,
                    customCode: getComparisonCode(v),
                    info
                };

                return option;
            });

        return {
            timeFilterOptions,
            comparisonOptions
        };
    }

    private getCustomOption() {
        const option = TimeFilterOptions.CUSTOM_OPTION_YEAR;
        option.timeFilter.from.date = this.dateSpan.from;
        option.timeFilter.to.date = this.dateSpan.to;
        return option;
    }

    public update(newOption?: TimeFilterOption, newComparisonOption?: TimeComparisonOption, ignoreEmit?: boolean) {
        this.hasComparisonPeroid = !this.withComparisonPeriodToggle || !isNullOrUndefined(newComparisonOption) || (this.editForm.controls.comparisonPeriodEnabled && this.editForm.controls.comparisonPeriodEnabled.value);

        if (this.isUpdating) {
            return;
        }

        this.isUpdating = true;

        const value: AlyQuickDatePickerTimeChange = this.editForm.value;

        const timeFilterOption = newOption || (value.optionCode === QuerySettingsTimeFilterOptionCode.CUSTOM ? this.getCustomOption() : TimeFilterOptions.getByCode(value.optionCode));
        const options = this.calcAvailableOptions(timeFilterOption);

        const comparisonOptionCode = this.hasComparisonPeroid ? (newComparisonOption ? newComparisonOption.code : value.comparisonOptionCode) : null;
        let currentComparison = options.comparisonOptions.find(comparisonOptions => comparisonOptions['customCode'] === comparisonOptionCode);

        if (this.hasComparisonPeroid && !currentComparison) {
            const ComparisonCode = TimeComparisonOptionCode;

            const comparisons = [
                { code: ComparisonCode.YearOverYearByDate, nearestOption: options.comparisonOptions.find(op => op.code === ComparisonCode.YearOverYearByWeekNumber) },
                { code: ComparisonCode.YearOverYearByWeekNumber, nearestOption: options.comparisonOptions.find(op => op.code === ComparisonCode.YearOverYearByDate) }
            ];

            for (let i = 0; i < comparisons.length; i++) {
                const comparison = comparisons[i];
                if (comparison.code === value.comparisonOptionCode && comparison.nearestOption) {
                    currentComparison = comparison.nearestOption;
                    break;
                }
            }

            if (!currentComparison) {
                currentComparison = options.comparisonOptions[0];
            }
        }

        if (this.hasComparisonPeroid && newComparisonOption && newComparisonOption.code === TimeComparisonOptionCode.DateRange) {
            currentComparison = newComparisonOption;
        }

        if (this.disableComparisonOption) {
            currentComparison = null;
        }

        this.timeFilterOptions = options.timeFilterOptions;
        this.comparisonOptions = options.comparisonOptions;

        this.title = timeFilterOption.label;
        this.dateSpan = this.timeFilterService.getTimePeriod(timeFilterOption.timeFilter);
        this.description = ' (' + this.datePipe.transform(this.dateSpan, { shorten: true }) + ')';

        this.apply(timeFilterOption, currentComparison, ignoreEmit);

        if (this.comparisonOption) {
            const newComparisonDateSpan = this.timeFilterService.getComparisonPeriod(timeFilterOption.timeFilter, this.comparisonOption.filter);
            if (newComparisonDateSpan.from && newComparisonDateSpan.to) {
                this.comparisonDateSpan = newComparisonDateSpan;
            }
        }

        this.isUpdating = false;
    }

    private getDateTime(date: number | Date) {
        if (!date) {
            return -1;
        }
        return typeof(date) === 'number' ? date : date.getTime();
    }

    private isChanged(option: TimeFilterOption, comparisonOption: TimeComparisonOption) {
        const isCustomSelected = option.code === QuerySettingsTimeFilterOptionCode.CUSTOM;

        let isNewOption = this.timeFilterOption.code !== option.code;

        if (isCustomSelected) {
            isNewOption = isNewOption ||
                this.getDateTime(this.timeFilterOption.timeFilter.from.date) !== this.getDateTime(option.timeFilter.from.date) ||
                this.getDateTime(this.timeFilterOption.timeFilter.to.date) !== this.getDateTime(option.timeFilter.to.date);
        }

        return isNewOption || !deepEqual(this.comparisonOption, comparisonOption);
    }

    private apply(option: TimeFilterOption, comparisonOption: TimeComparisonOption, ignoreEmit?: boolean) {

        const isOptionSync = this.editForm.controls.optionCode.value !== option.code;
        const isComparisonSync = (comparisonOption && this.editForm.controls.comparisonOptionCode.value !== comparisonOption['customCode']) || (!this.hasComparisonPeroid && this.editForm.controls.comparisonOptionCode.value);

        const isCustomFromSync = this.dateSpan.from.getTime() !== this.editForm.value.fromDate.getTime();
        const isCustomToSync = this.dateSpan.to.getTime() !== this.editForm.value.toDate.getTime();

        if (isOptionSync) {
            this.editForm.controls.optionCode.setValue(option.code);
        }

        if (isComparisonSync) {
            let newValue = comparisonOption ? comparisonOption['customCode'] : null;
            if (comparisonOption && comparisonOption.code === TimeComparisonOptionCode.DateRange) {
                newValue = TimeComparisonOptionCode.DateRange;
            }
            this.editForm.controls.comparisonOptionCode.setValue(newValue);
        }

        if (isCustomFromSync) {
            this.editForm.controls.fromDate.setValue(this.dateSpan.from);
        }

        if (isCustomToSync) {
            this.editForm.controls.toDate.setValue(this.dateSpan.to);
        }

        if (!!this.comparisonDateSpan.from && this.comparisonDateSpan.from.getTime() !== this.editForm.value.comparisonFromDate.getTime()) {
            this.editForm.controls.comparisonFromDate.setValue(this.comparisonDateSpan.from);
        }

        if (!!this.comparisonDateSpan.to && this.comparisonDateSpan.to.getTime() !== this.editForm.value.comparisonToDate.getTime()) {
            this.editForm.controls.comparisonToDate.setValue(this.comparisonDateSpan.to);
        }

        if (this.isChanged(option, comparisonOption)) {
            this.timeFilterOption = option;
            this.comparisonOption = comparisonOption;

            if (!ignoreEmit) {
                this.changes.emit();
            }
        }

    }

}

export class AlyQuickDatePickerTimeChange {
    fromDate: Date;
    toDate: Date;
    optionCode: QuerySettingsTimeFilterOptionCode;
    comparisonOptionCode: TimeComparisonOptionCode;
}

class ComparisonPeriodsMap {
    public periods: Map<TimeComparisonOptionCode | ExtendedComparisonCode, TimeTypeCode[]>;

    constructor(
        public matchedOptions: QuerySettingsTimeFilterOptionCode[],
        periods: [(TimeComparisonOptionCode | ExtendedComparisonCode), TimeTypeCode[]][],
        public allPeriods?: TimeComparisonOptionCode[]
    ) {
        this.periods = new Map(periods);
    }
}
const createGroupConfig = (displayFiscalYearGroup: boolean): MnbDropdownInputGroupConfig => {

        const result = [
            new MnbDropdownInputGroup('GENERAL.TIME.TIME_OPTIONS.CUSTOM_HEADING', null),
            new MnbDropdownInputGroup('GENERAL.TIME.LABEL.DAYS', TimeTypeCode.Days),
            new MnbDropdownInputGroup('GENERAL.TIME.LABEL.WEEKS', TimeTypeCode.Weeks),
            new MnbDropdownInputGroup('GENERAL.TIME.LABEL.MONTHS', TimeTypeCode.Months),
            new MnbDropdownInputGroup('GENERAL.TIME.LABEL.QUARTERS', TimeTypeCode.Quarters),
            new MnbDropdownInputGroup('GENERAL.TIME.LABEL.YEARS', TimeTypeCode.Years),
        ];

        if (displayFiscalYearGroup) {
            result.push(new MnbDropdownInputGroup('GENERAL.TIME.LABEL.FISCAL_YEARS', TimeTypeCode.FiscalYears));
        }

        return new MnbDropdownInputGroupConfig(result, 'typeCode');
};

export interface MnbQuickTimeFilterValue {
    timeFilter?: QuerySettingsTimeFilter;
    comparisonFilter?: QuerySettingsComparisonFilter;
}
