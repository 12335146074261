import { CalendarConfig } from '@shared-lib/modules/config/model/mnb-config.model';
import { FeatureRightCode } from '../modules/session/model/feature-right.model';
import { LoginOptins } from './resource/tenant.model';

export class MnbSession {
    customer?: SessionCustomer;
    featureToggles?: any; // TODO:
    login?: SessionLogin;
    tenant?: SessionTenant;
    featureRights?: FeatureRights;
    tenantLogins?: SessionTenantLogin[];
    sessionHash?: string;
    languageIso3?: string;
    localeCode?: 'de' | 'en' | 'en_US';
}

export class FeatureRights {
    restrictedFeatures: FeatureRightCode[];
}

export class SessionCustomer {
    // TODO: implement when needed
    name: string;
    id: number;
    dunningDate: Date|number;
    trialEndDate: Date|number;
}

export class SessionLogin {
    id: number;
    isAdmin: boolean;
    isInternal: boolean;
    isDemoUser: boolean;
    isFeedAdmin: boolean;
    isMinuboLoginAdmin: boolean;
    isTenantAdmin: boolean;
    isNstAdmin: boolean;
    hasSafePassword: boolean;

    mail: string;

    optins: LoginOptins;
}

export class SessionTenant {
    name: string;
    tenantTypeCode: string;
    calendarConfig: CalendarConfig;
    isDemoTenant: boolean;
    settings: SessionTenantSettings;
    fiscalYearStartDate?: string;
    currencyCode: string;
}

export class SessionTenantSettings {
    isGoogleSignInOnly: boolean;
    featureToggles: string[];
}

export class SessionTenantLogin {
    isDeactivated: boolean;
    isExternal: boolean;
}


export enum MnbFeatureToggle {
    ANALYSIS_LEGACY = 'analysis.legacy',
    ANALYSIS_RFM = 'analysis.rfm',
    FEEDS_LEGACY = 'feeds.legacy',
    WIP_FEATURES = 'wip-features'
}
